<template>
    <div class="sm:min-w-[30rem]">
        <h4 class="flex items-center justify-between gap-2 flex-wrap">
            <span class="block font-medium text-wgm-blue-800 text-sm">{{ $t('dashboard.notifications.notification') }}</span>
            <div
                v-if="notification.template.actor"
                class="flex items-center gap-2 text-wgm-gray-800 text-sm"
            >
                <RouterLink
                    v-if="auth.hasPermissionTo(Permission.Global_UserManagement_Overview)"
                    class="hover:underline"
                    :to="auth.user?.uuid !== notification.template.actor.uuid
                        ? { name: 'users.show', params: { uuid: notification.template.actor.uuid }}
                        : { name: 'my-account'}"
                >
                    {{ notification.template.actor.name }}
                </RouterLink>
                <span v-else>{{ notification.template.actor.name }}</span>
                <img
                    v-if="notification.template.actor.image"
                    :src="notification.template.actor.image"
                    alt=""
                    height="40"
                    width="40"
                    class="object-cover rounded-full"
                >
                <DefaultUserIcon
                    v-else
                    rounded
                    class="w-10 h-10 sm:w-10 sm:h-10"
                />
            </div>
            <div
                v-else
                class="flex items-center gap-2 text-wgm-gray-800 text-sm"
            >
                <span>Westfalen Medical</span>
                <img
                    src="../../assets/logo.png"
                    alt=""
                    height="40"
                    width="40"
                    class="object-cover"
                >
            </div>
        </h4>
        <h3 class="text-xl mb-6 sm:flex gap-1 font-semibold">
            {{ i18n.te(title[0]) ? $t(...title) : title[0] }}
        </h3>
        <div :class="{'mb-9': !notification.template.link}">
            {{ i18n.te(body[0]) ? $t(...body) : body[0] }}
        </div>
        <div
            v-if="isValidLink(notification.template.link) && link"
            class="text-sm mt-2 mb-9 text-wgm-blue-900 hover:underline"
        >
            <RouterLink :to="link">
                {{ i18n.te(notification.template.link.label) ? $t(notification.template.link.label) : notification.template.link.label }}
            </RouterLink>
        </div>
        <div class="text-sm text-wgm-gray-400 flex justify-between items-center gap-2 flex-wrap">
            <button
                type="button"
                class="px-4 py-2 rounded bg-wgm-blue-900 text-white hover:bg-wgm-blue-800"
                @click="() => notificationStore.markNotificationAsUnread(notification.uuid)"
            >
                {{ $t('dashboard.notifications.mark_as_unread') }}
            </button>
            <div class="flex items-center">
                <IconClock class="scale-50 text-black fill-current" />
                <span>{{ useDatetime(notification.created_at).toHumanReadable() }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useDatetime } from '~/lib/datetime';
import { normalizeRoute } from '~/lib/router/RouterPipeline';
import useAuth from '~/stores/Auth';
import useNotification from '~/stores/Notification';
import { Permission } from '~/typings/enums';
import { isString } from '~/helpers';
import type { RouteLocationRaw } from 'vue-router';
import type { Notification } from '~/stores/Notification';
import type { i18nKey } from '~/typings/types';

type Link = Notification['template']['link'];

interface Props {
    notification: Notification;
}

const props = defineProps<Props>();
const auth = useAuth();
const notificationStore = useNotification();
const i18n = useI18n();

const isValidLink = (link: Partial<Link>): link is NonNullable<Link> => {
    if (!link?.vue_route) {
        return false;
    }

    if (link.vue_route.path) {
        return true;
    }

    if (!link.vue_route.name) {
        return false;
    }

    try {
        normalizeRoute(link.vue_route.name, link.vue_route.params);

        return true;
    } catch {
        return false;
    }
};

const getLink = (link: Link): RouteLocationRaw|null => {
    if (!link) {
        return null;
    }

    if (link.vue_route.path) {
        return {
            path: link.vue_route.path,
            query: link.vue_route.query,
            hash: link.vue_route.hash,
        };
    }

    if (!link.vue_route.name) {
        return null;
    }

    return {
        name: link.vue_route.name,
        params: link.vue_route.params,
        query: link.vue_route.query,
        hash: link.vue_route.hash,
    };
};

const link = getLink(props.notification.template.link);

const title = computed<[i18nKey, Record<string, string>]>(() => {
    if (!props.notification) {
        return ['', {}];
    }

    return isString(props.notification.template.title)
        ? [props.notification.template.title, {}]
        : props.notification.template.title;
});

const body = computed<[i18nKey, Record<string, string>]>(() => {
    if (!props.notification) {
        return ['', {}];
    }

    return isString(props.notification.template.body)
        ? [props.notification.template.body, {}]
        : props.notification.template.body;
});
</script>
