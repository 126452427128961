import { TourName } from '~/typings/enums';
import caregroup from '~/helpers/tours/caregroup';
import dashboard from '~/helpers/tours/dashboard';
import therapyOverview from '~/helpers/tours/therapy-overview';
import therapyRequest from '~/helpers/tours/therapy-request';
import therapyView from '~/helpers/tours/therapy-view';

import type { TourStep } from '~/typings/types';

const tours = [
    caregroup,
    dashboard,
    therapyOverview,
    therapyRequest,
    therapyView,
];

export const getTourName = (routeName?: string): TourName | undefined => {
    switch (routeName) {
        case 'caregroups.show':
            return TourName.Caregroup;
        case 'dashboard':
            return TourName.Dashboard;
        case 'therapy.overview':
            return TourName.TherapyOverview;
        case 'therapy.main':
        case 'therapy.oxygen':
        case 'therapy.cluster-headache':
        case 'therapy.nebulization-without-antibiotics':
        case 'therapy.nebulization-with-antibiotics':
        case 'therapy.bronchiectasis':
        case 'therapy.cystic-fibrosis':
        case 'therapy.positive-expiratory-pressure':
        case 'therapy.positive-airway-pressure':
        case 'therapy.position-therapy':
        case 'therapy.nasal-high-flow':
            return TourName.TherapyRequest;
        case 'therapy.detail':
            return TourName.TherapyView;
        default:
            return undefined;
    }
};

export const getTourSteps = async (name: string): Promise<TourStep[]> => {
    const currentTour = tours.find(tour => tour.name === name);

    if (!currentTour) {
        return [];
    }

    const { steps } = await currentTour.data();

    return steps;
};

export const tourNames: TourName[] = tours.map(tour => tour.name);
