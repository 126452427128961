import { TourName } from '~/typings/enums';

export default {
    name: TourName.Dashboard,
    data(): any {
        return {
            steps: [
                {
                    target: '[data-v-step="dashboard:welcome"]',
                    params: {
                        placement: 'bottom',
                    },
                    content: `
                        <div class="flex-column text-left">
                            <h5 class="font-medium mb-2">
                                Welkom!
                            </h5>
                            <p>
                                Op het dashboard vind u een overzicht van uw notificaties.
                            </p>
                        </div>
                    `,
                },
                {
                    target: '[data-v-step="dashboard:notifications"]',
                    params: {
                        placement: 'right',
                    },
                    content: `
                        <div class="flex-column text-left">
                            <h5 class="font-medium mb-2">
                                Notificaties
                            </h5>
                            <p>
                                Door een notificatie aan te klikken opent u deze en kunt u de details bekijken.
                            </p>
                        </div>
                    `,
                },
                {
                    target: '[data-v-step="dashboard:bell"]',
                    params: {
                        placement: 'bottom',
                    },
                    content: `
                        <div class="flex-column text-left">
                            <h5 class="font-medium mb-2">
                                Notificatie
                            </h5>
                            <p>
                                Ook hier zijn notificaties te allen tijden in te zien. Een gekleurd bolletje
                                betekent dat er nieuwe notificaties zijn.
                            </p>
                        </div>
                    `,
                },
                {
                    target: '[data-v-step="dashboard:menu"]',
                    params: {
                        placement: 'right',
                    },
                    content: `
                        <div class="flex-column text-left">
                            <h5 class="font-medium mb-2">
                                Menu
                            </h5>
                            <p>
                                Vanuit het menu heeft u toegang tot het portaal. Dit is onderverdeeld in de
                                voor u relevante onderdelen.
                            </p>
                        </div>
                    `,
                },
            ],
        };
    },
};
