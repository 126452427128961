import { TourName } from '~/typings/enums';

export default {
    name: TourName.TherapyRequest,
    data(): any {
        return {
            steps: [
                {
                    target: '[data-v-step="therapy-request:therapy_type"]',
                    params: {
                        placement: 'bottom',
                    },
                    content: `
                        <div class="flex-column text-left">
                            <h5 class="font-medium mb-2">
                                Therapie aanvragen of wijzigen
                            </h5>
                            <p>
                                Kies de gewenste therapie.
                            </p>
                        </div>
                    `,
                },
                {
                    target: '[data-v-step="therapy-request:request_type"]',
                    params: {
                        placement: 'top',
                    },
                    content: `
                        <div class="flex-column text-left">
                            <h5 class="font-medium mb-2">
                                Therapie aanvragen of wijzigen
                            </h5>
                            <p>
                                Kies het soort verzoek.
                            </p>
                        </div>
                    `,
                },
            ],
        };
    },
};
