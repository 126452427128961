import createModel, { defineModel } from '~/lib/model';
import useAuth from '~/stores/Auth';
import useHttp from '~/lib/http';
import { TherapyType, TherapyRequestType, MedicalIndication } from '~/typings/enums';
import { buildFormData } from '~/helpers/forms';
import type { z } from 'zod';
import type { TherapyRequestEnvironment, TherapyStatus, SapStatus } from '~/typings/enums';
import type { Model, ModelDefinition } from '~/typings/model';
import type { Uuid } from '~/typings/types';
import type { AnyObject } from '~/typings/interfaces';
import type {
    CysticFibrosisSchema,
    OxygenSchema,
    BronchiectasisSchema,
    PositionTherapySchema,
    ClusterHeadacheSchema,
    PatientSchema,
    SpecialistSchema,
    OtherRelevantCriteriaSchema,
    PositiveAirwayPressureSchema,
    DeliveryAddressSchema,
    HealthInsuranceSchema,
    PharmacySchema,
    PositiveExpiratoryPressureSchema,
    AuthTherapySchema,
    NebulizationWithAntibioticsSchema,
    NebulizationWithoutAntibioticsSchema,
    TherapyBaseSchema,
    NebulizationAntibioticsBaseSchema,
    NasalHighFlowSchema,
} from '~/schemas/Therapy';
import type { HttpMethod } from '~/typings/http';

export interface Therapy<P extends AnyObject = Record<string, any>> extends Model {
    therapy_type: TherapyType;
    request_type: TherapyRequestType;
    request_environment: TherapyRequestEnvironment;
    status: TherapyStatus;
    payload: P;
    patient_uuid: Uuid | null;
    patient_name: string | null;
    patient_social_security_number: string;
    specialist_uuid: Uuid | null;
    specialist_name: string | null;
    specialist_big_number: string | null;
    requestee_uuid: Uuid | null;
    requestee_name: string | null;
    validator_uuid: Uuid | null;
    validator_name: string | null;
    created_at: string;
    updated_at: string | null;
    validated_at: string | null;
    synced_to_sap_at: string | null;
    sap_status: SapStatus;
    is_legacy: boolean;
}

export type NebulizationWithAntibioticsMedicine = {
    r_value: string | null;
    s_value: string | null;
    n_value: string | null;
    months: number | null;
}

export type NebulizationWithOutAntibioticsMedicine = {
    name: string | null;
    dosage: string | null;
}

export type Medicine = NebulizationWithOutAntibioticsMedicine | NebulizationWithAntibioticsMedicine;

// Base therapy
export type TherapyBase = z.infer<typeof TherapyBaseSchema>

// Contact information
type Patient = z.infer<typeof PatientSchema>
type Pharmacy = z.infer<typeof PharmacySchema>
type Specialist = z.infer<typeof SpecialistSchema>
type DeliveryAddress = z.infer<typeof DeliveryAddressSchema>
type OtherRelevantCriteria = z.infer<typeof OtherRelevantCriteriaSchema>
type AuthTherapy = z.infer<typeof AuthTherapySchema>

// Therapies
type Oxygen = z.infer<typeof OxygenSchema>
type NebulizationAntibioticsBase = z.infer<typeof NebulizationAntibioticsBaseSchema>
type NebulizationWithoutAntibiotics = z.infer<typeof NebulizationWithoutAntibioticsSchema>
type NebulizationWithAntibiotics = z.infer<typeof NebulizationWithAntibioticsSchema>
type HealthInsurance = z.infer<typeof HealthInsuranceSchema>
type CysticFibrosis = z.infer<typeof CysticFibrosisSchema>
type Bronchiectasis = z.infer<typeof BronchiectasisSchema>
type PositionTherapy = z.infer<typeof PositionTherapySchema>
type ClusterHeadache = z.infer<typeof ClusterHeadacheSchema>
type PositiveAirwayPressure = z.infer<typeof PositiveAirwayPressureSchema>
type PositiveExpiratoryPressure = z.infer<typeof PositiveExpiratoryPressureSchema>
type NasalHighFlow = z.infer<typeof NasalHighFlowSchema>

// Meh
export type OxygenPayload = Partial<Oxygen>
export type NebulizationAntibioticsBasePayload = Partial<NebulizationAntibioticsBase>
export type NebulizationWithoutAntibioticsPayload = Partial<NebulizationWithoutAntibiotics>
export type NebulizationWithAntibioticsPayload = Partial<NebulizationWithAntibiotics>
export type HealthInsurancePayload = Partial<HealthInsurance>
export type CysticFibrosisPayload = Partial<CysticFibrosis>
export type BronchiectasisPayload = Partial<Bronchiectasis>
export type PositionTherapyPayload = Partial<PositionTherapy>
export type ClusterHeadachePayload = Partial<ClusterHeadache>
export type PositiveAirwayPressurePayload = Partial<PositiveAirwayPressure>
export type PositiveExpiratoryPressurePayload = Partial<PositiveExpiratoryPressure>
export type NasalHighFlowPayload = Partial<NasalHighFlow>

export type PatientPayload = Partial<Patient>
export type PharmacyPayload = Partial<Pharmacy>
export type SpecialistPayload = Partial<Specialist>
export type DeliveryAddressPayload = Partial<DeliveryAddress>
export type OtherRelevantCriteriaPayload = Partial<OtherRelevantCriteria>
export type AuthTherapyPayload = Partial<AuthTherapy>

interface TherapyPayload extends TherapyBase, Partial<Oxygen & AuthTherapy> { }
interface StopTherapyPayload extends TherapyBase, Partial<AuthTherapy> { therapy_type: TherapyType }

export const oxygenMedicalIndicationList = [
    MedicalIndication.COPD,
    MedicalIndication.InterstitialLungDisease,
    MedicalIndication.LungcarcinomaOrMetastases,
    MedicalIndication.ClusterHeadache,
    MedicalIndication.HeartFailure,
    MedicalIndication.CysticFibrosis,
    MedicalIndication.TerminalStageDisease,
    MedicalIndication.HypoxemiaDuringExercise,
    MedicalIndication.BronchopulmonaryDysplasia,
    MedicalIndication.NocturnalHypoxemia,
    MedicalIndication.Palliative,
    MedicalIndication.Covid,
];

export const nebulizationWithAntibioticsMedicalIndicationList = [
    MedicalIndication.PersistentAsthma,
    MedicalIndication.AsthmaExacerbation,
    MedicalIndication.SevereCOPD,
    MedicalIndication.COPDExacerbation,
    MedicalIndication.Bronchiectasis,
    MedicalIndication.Covid,
    MedicalIndication.PseudomonasInfection,
];

export const nebulizationWithoutAntibioticsMedicalIndicationList = [
    MedicalIndication.Asthma,
    MedicalIndication.PersistentAsthma,
    MedicalIndication.AsthmaExacerbation,
    MedicalIndication.SevereCOPD,
    MedicalIndication.COPDExacerbation,
    MedicalIndication.COPD,
    MedicalIndication.Bronchiectasis,
    MedicalIndication.Covid,
    MedicalIndication.PseudomonasInfection,
];

export const positiveExpiratoryPressureMedicalIndicationList = [
    MedicalIndication.AsthmaExacerbation,
    MedicalIndication.COPD,
    MedicalIndication.CysticFibrosis,
    MedicalIndication.Bronchiectasis,
    MedicalIndication.Covid,
];

const therapyModel = createModel('therapy', defineModel<Therapy>(), {
    getters: {
        hasTherapyPermission() {
            return (!useAuth().authenticatedUser?.permissions.some(perm => perm.startsWith('therapy.')));
        },
        therapies(): Map<TherapyType, TherapyRequestType[]> {
            return new Map<TherapyType, TherapyRequestType[]>([
                [TherapyType.Oxygen, [TherapyRequestType.New, TherapyRequestType.Mutation, TherapyRequestType.Stop]],
                [TherapyType.NebulizationWithAntibiotics, [TherapyRequestType.New, TherapyRequestType.Stop]],
                [TherapyType.NebulizationWithoutAntibiotics, [TherapyRequestType.New, TherapyRequestType.Stop]],
                [TherapyType.ClusterHeadache, [TherapyRequestType.New, TherapyRequestType.Stop]],
                [TherapyType.BE, [TherapyRequestType.New, TherapyRequestType.Stop]],
                [TherapyType.PEP, [TherapyRequestType.New, TherapyRequestType.Stop]],
                [TherapyType.CF, [TherapyRequestType.New, TherapyRequestType.Stop]],
                [TherapyType.PAP, [TherapyRequestType.New, TherapyRequestType.Mutation, TherapyRequestType.Approval, TherapyRequestType.Stop]],
                [TherapyType.NasalHighFlow, [TherapyRequestType.New, TherapyRequestType.Mutation, TherapyRequestType.Stop]],
                [TherapyType.SPT, [TherapyRequestType.New, TherapyRequestType.Approval, TherapyRequestType.Stop]]]);
        },
    },
    actions: {
        async newTherapyRequest(payload: TherapyPayload, therapyType: TherapyType) {
            const endpoint = useAuth().isGuest
                ? `therapy/guest/${therapyType}`
                : `therapy/auth/${therapyType}/${payload.patient_uuid}`;

            return this.submitTherapyRequest('post', endpoint, payload);
        },
        async mutateTherapyRequest(payload: TherapyPayload, therapyType: TherapyType) {
            const endpoint = useAuth().isGuest
                ? `therapy/guest/${therapyType}`
                : `therapy/auth/${therapyType}/${payload.patient_uuid}`;

            return this.submitTherapyRequest('patch', endpoint, payload);
        },
        async approveTherapyRequest(payload: TherapyPayload, therapyType: TherapyType) {
            const endpoint = useAuth().isGuest
                ? `therapy/guest/${therapyType}/approval`
                : `therapy/auth/${therapyType}/approval/${payload.patient_uuid}`;

            return this.submitTherapyRequest('post', endpoint, payload);
        },
        async stopTherapyRequest(payload: StopTherapyPayload) {
            const endpoint = useAuth().isGuest
                ? 'therapy/guest/stop'
                : `therapy/auth/stop/${payload.patient_uuid}`;

            return this.submitTherapyRequest('delete', endpoint, payload);
        },
        async submitTherapyRequest(method: Exclude<HttpMethod, 'get'>, endpoint: string, payload?: any) {
            const data = new FormData();
            buildFormData(data, payload);

            if (method !== 'post') {
                data.append('_method', method.toUpperCase());
            }

            return useHttp().post(endpoint, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
        },
    },
});

const useTherapy = (): ModelDefinition<typeof therapyModel> => therapyModel();

export default useTherapy;
